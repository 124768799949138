import React, {useEffect} from 'react';
import Gallery from 'react-grid-gallery';

export default function GalleryExample(props) {

    const { photos } = props;
    // This gallery lib use react-image for the carousel


    useEffect(() => {
        photos.forEach(item => {
            if(!item.src) {
                item.src = item.photo.childImageSharp.fluid.src
                item.thumbnail = item.thumbnail.childImageSharp.fluid.src
                item.thumbnailWidth = 320
            }
        })
    });

    return (
        <div>
            <Gallery images={photos}
                     enableImageSelection={false}
                     backdropClosesModal
                     style={{ margin: "0 auto"}}
            />
        </div>
    );
};