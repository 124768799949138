import React, { useEffect} from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'

import GalleryExample from "../components/GalleryExample";

export const BlogPostTemplate = ({
                                     description,
                                     title,
                                     gallery,
                                     helmet,
                                     travaux,
                                 }) => {


    useEffect(() => {
        gallery.forEach(item => {
            // console.log("item : ", item )
            if(!item.src) {
                item.src = item.thumbnail.childImageSharp.fluid.src
                item.photoFull = item.photo.childImageSharp.fluid.src
            }
            if(!item.width) {
                item.width = 1
                item.height = 1
            }
        })
        console.log("gallery :", gallery)

    });

    return (
        <section className="section">
            {helmet || ''}
            <div className="container content">
                <div className="columns">
                    <div className="column is-12">
                        <h3 className="title has-text-centered" style={{ color : "#b3722d" }}>
                            {title}
                        </h3>
                        <p>{description}</p>

                        <h3 className="" style={{marginTop: "0.5rem", marginBottom: "1.5rem"}}>Travaux effectués :</h3>
                        <div className="columns is-multiline" style={{ paddingRight: "2rem", paddingLeft: "2rem", paddingBottom: "1rem"}}>
                            {travaux.map((value, index) => {
                                return (
                                    <div className="column is-6" style={{ padding: "0.25rem"}}>
                                        <li>{value}</li>
                                    </div>
                                )
                            })}
                        </div>



                        <div className="has-text-centered is-centered">
                            <GalleryExample photos={gallery}  />
                        </div>

                        {/* Float needs to be cleared with this css so the footer stay at its place :*/}
                        <p style={{clear:"both"}}></p>

                        <div className="has-text-centered" style={{ fontStyle: "italic"}}>
                            <p> Cliquez sur les images pour les agrandir !</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

BlogPostTemplate.propTypes = {
    content: PropTypes.node.isRequired,
    contentComponent: PropTypes.func,
    description: PropTypes.string,
    title: PropTypes.string,
    helmet: PropTypes.object,
    gallery: PropTypes.array,
    travaux: PropTypes.array,
}

const BlogPost = ({ data }) => {
    const { markdownRemark: post } = data

    return (
        <Layout>
            <BlogPostTemplate
                content={post.html}
                contentComponent={HTMLContent}
                description={post.frontmatter.description}
                helmet={
                    <Helmet titleTemplate="%s | Blog">
                        <title>{`${post.frontmatter.title}`}</title>
                        <meta
                            name="description"
                            content={`${post.frontmatter.description}`}
                        />
                    </Helmet>
                }
                title={post.frontmatter.title}
                gallery={post.frontmatter.gallery}
                travaux={post.frontmatter.travaux}
            />
        </Layout>
    )
}

BlogPost.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
    }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        gallery {
          photo {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
          }
          thumbnail {
              childImageSharp {
                fluid(maxWidth: 320, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
          }
          caption
        }
        travaux
      }
    }
  }
`
